/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import Logo1 from '../assets/Logo1.png';
import '../stylings/MenuStyling.css';
import TextCarousel from '../components/TextCarousel';
import CookieModal from '../components/CookieModal';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useNavigate } from 'react-router-dom';

function Menu(props) {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    firebase.auth().onAuthStateChanged((u) => {
        if (u) {
            setUser(u)
            navigate('/dashboard');
        } else {
            setUser(null);
        }
    });

    useEffect(() => {

    }, [user])

    return (
        <>
        <CookieModal></CookieModal>
        
            <Card id='card'>
                <Card.Header className="d-flex justify-content-between align-items-center menu-card-header">
                    <div className="align-items-center" style={{ marginRight: 8 }}>
                        <Image src={`${Logo1}`} style={{ width: 200 }} />
                    </div>
                    <a href="/login" className="btn btn-primary">Login</a>
                </Card.Header>
                <Card.Body className="menu-card-body">
                    <div className="background-image">
                        <div className="menu-card-content-1">
                            <h1>Willkommen in der Schuhdatenbank</h1>
                        </div>
                        <div className="menu-card-content-2">
                            Hier sind einige aufregende Funktionen, die Sie auf unserer Website erwarten:
                            <TextCarousel />
                        </div>
                        <div className="menu-card-content-3">
                            <p>
                                Unsere Website wurde speziell entwickelt, um Ihre Leidenschaft für Schuhe zu unterstützen und zu bereichern,
                                indem sie Ihnen die Möglichkeit bietet, Ihre wertvolle Schuhsammlung an einem Ort zu speichern und mühelos zu
                                verwalten.
                            </p>
                            <p>
                                Wir sind stolz darauf, eine Plattform geschaffen zu haben, die speziell auf die Bedürfnisse von
                                Schuhliebhabern und Sammlern zugeschnitten ist. <br></br>Vielen Dank, dass Sie Teil unserer Community sind, und wir
                                freuen uns darauf, Sie auf Ihrer Reise durch die faszinierende Welt der Schuhe zu begleiten!
                            </p>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-center menu-card-footer">
                    <p style={{ marginTop: 8, fontSize: 12, color: '#A1A1A1' }}>© 2023 Justus Rademacher</p>
                    <p style={{ marginTop: 8, fontSize: 12, color: '#A1A1A1' }}><a href="/impressum">Impressum</a> • <a href="/datenschutz">Datenschutz</a></p>
                </Card.Footer>
            </Card>
        </>
    );
}

export default Menu;