import React, { useState } from 'react';
import { Card, Form, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Logo1 from '../assets/Logo1.png'
import GoogleLogo from '../assets/Google_G.png';
import NotificationModal from '../components/NotificationModal';
import CookieModal from '../components/CookieModal';


function Login(props) {
    const navigate = useNavigate();
    const [validate, setValidated] = useState(false);
    const [user, setUser] = useState(null);
    const [readyToNavigate, setReadyToNavigate] = useState(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showMailError, setshowMailError] = useState('');
    const [showGoogleError, setshowGoogleError] = useState('');
    const [googleErrorMessage, setGoogleErrorMessage] = useState('');

    firebase.auth().onAuthStateChanged((u) => {
        if (u) {
            setUser(u);
            // Delay navigation decision
            if (readyToNavigate) {
                navigate('/dashboard');
            }
        } else {
            setUser(null);
        }
    });

    const handleShowConfirmation = (message) => {
        setConfirmationMessage(message);
        setShowConfirmationModal(true);
    };

    const handleGoogleError = (message) => {
        setGoogleErrorMessage(message);
        setshowGoogleError(true);
    }

    const handleGoogleLogin = async () => {
        setReadyToNavigate(false);
        setshowMailError(false);
        setshowGoogleError(false);
        const provider = new firebase.auth.GoogleAuthProvider();
        try {
            const userCredential = await firebase.auth().signInWithPopup(provider);
            const user = userCredential.user;
            const userEmail = user.email;
            // Check if the user already exists with email/password authentication
            const emailAuthUser = await firebase
                .auth()
                .fetchSignInMethodsForEmail(userEmail);

            if (emailAuthUser.length > 1) {
                // User exists with email/password, show error message
                handleGoogleError("Der Benutzer existiert bereits mit E-Mail/Passwort-Authentifizierung. Bitte verwenden Sie diese Methode, um sich anzumelden.")
                setValidated(false);
                firebase.auth().signOut(); // Sign out the user from Google authentication
                return;
            }

            // User doesn't exist with email/password, proceed with Google authentication
            const userExists = await firebase
                .firestore()
                .collection('benutzerVerwaltung')
                .where('email', '==', userEmail)
                .get();
            if (userExists.empty) {
                handleGoogleError("User ist nicht für den Google-Login freigeschaltet. Bitte beim Admin melden.")
                setValidated(false);
                firebase.auth().signOut(); // Sign out the user from Google authentication
            } else {
                handleShowConfirmation('Google-Login erfolgreich.');
                setUser(user);
                setValidated(true);                
                setReadyToNavigate(true);
                setTimeout(() => {
                    navigate('/dashboard');
                }, 2000);
            }
        } catch (error) {
            handleShowConfirmation("Ein Fehler trat auf:: " + error.message);
            setValidated(true);
        }
    };

    const handleEmailLogin = (e) => {
        setshowMailError(false);
        setshowGoogleError(false);
        e.preventDefault();
        const { email, password } = e.target.elements;
        firebase
            .auth()
            .signInWithEmailAndPassword(email.value, password.value)
            .then((userCredentials) => {
                const user = userCredentials.user;
                setReadyToNavigate(true);
                handleShowConfirmation('Login erfolgreich.');
                setUser(user);
                setValidated(true);
                setTimeout(() => {
                    navigate('/dashboard');
                }, 2000);
            })
            .catch((error) => {
                setshowMailError(true);
                setValidated(true);
            });
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();
        const email = document.getElementById('email').value;

        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                handleShowConfirmation('Passwort zurücksetzen - E-Mail gesendet.');
            })
            .catch((error) => {
                handleShowConfirmation('Fehler beim Zurücksetzen des Passworts: ' + error.message);
            });
    };

    return (
        <>
         <CookieModal></CookieModal>
            {showConfirmationModal && (
                <NotificationModal
                    message={confirmationMessage}
                    onClose={() => setShowConfirmationModal(false)}
                />
            )}
            {user === null && (
                <Card style={{ margin: 24 }}>
                    <Card.Header>
                        <Image src={Logo1} style={{ width: 150, marginBottom: 8 }} />
                        <h4>Benutzer-Login</h4>
                        <p style={{ marginTop: 8, fontSize: 12, color: '#A1A1A1' }}>
                            Wenn du ein Konto auf dieser Seite hast, logge dich ein. Wenn du keins hast, wende dich bitte an
                            den Admin dieser Seite.
                        </p>
                    </Card.Header>
                    <Card.Body style={{ display: 'flex', flexDirection: 'column', maxWidth: "500px" }}>
                        <Button variant="outline-primary" size="md" style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }} onClick={handleGoogleLogin}>
                            <img src={GoogleLogo} alt="Google Logo" style={{ marginRight: '0.5em', height: '1.2em' }} />
                            <span>Mit Google anmelden</span>
                        </Button>
                        {showGoogleError && (
                            <div style={{ backgroundColor: '#f8d7da', border: '1px solid #f5c6cb', color: '#721c24', padding: '10px', marginTop: '10px' }}>
                                <span style={{ fontSize: '14px' }}>
                                    {googleErrorMessage}
                                </span>
                            </div>
                        )}

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '16px 0' }}>
                            <hr style={{ flex: '1', margin: '0 8px' }} />
                            <span style={{ fontSize: '14px', color: '#777' }}>Oder, melde dich mit deiner Email an</span>
                            <hr style={{ flex: '1', margin: '0 8px' }} />
                        </div>
                        <Form noValidate validated={validate} onSubmit={handleEmailLogin}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Benutzer Email" size="md" required />
                                <Form.Control.Feedback type="invalid">Bitte Email eingeben.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Passwort</Form.Label>
                                <Form.Control type="password" placeholder="Passwort" size="md" required />
                                <Form.Control.Feedback type="invalid">Bitte Passwort eingeben.</Form.Control.Feedback>
                            </Form.Group>
                            {showMailError && (
                                <div style={{ backgroundColor: '#f8d7da', border: '1px solid #f5c6cb', color: '#721c24', padding: '10px', marginTop: '10px', marginBottom: '10px' }}>
                                    <span style={{ fontSize: '14px' }}>
                                        Mail oder Passwort falsch.
                                    </span>
                                </div>
                            )}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Button variant="primary" type="submit" size="md" style={{ fontWeight: 'bold', marginRight: 10 }}>
                                    Login ❯
                                </Button>

                                <Button variant="link" size="md" style={{ fontSize: '14px' }} onClick={handleForgotPassword}>
                                    Passwort vergessen?
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        <a href="/" style={{ marginTop: 8, fontSize: 12 }}>
                            ← Zurück zur Startseite
                        </a>
                    </Card.Footer>
                </Card>
            )}

        </>
    );
}

export default Login;