import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../stylings/TextCarousel.css'; // Hier kannst du deine eigenen Stile hinzufügen

function TextCarousel() {
    return (
        <Carousel
            autoPlay
            interval={5000}
            infiniteLoop // Das Karussell wird endlos weitergeführt
            showStatus={false} // Zeige den Status nicht an
            showThumbs={false} // Zeige keine Thumbnails an
        >
            <div className="carousel-item">
                <h3>Persönlicher Schuhkatalog:</h3>
                <p>Erstellen Sie Ihren eigenen, individuellen Schuhkatalog.</p>
            </div>
            <div className="carousel-item">
                <h3>Einfache Dateneingabe:</h3>
                <p> Neue Schuhe problemlos hinzufügen, inklusive Marke, Modell, Größe, Farbe und Erwerbsjahr.</p>
            </div>
            <div className="carousel-item">
            <h3>Bildergalerie:</h3>
                <p> Hochwertige Bilder Ihrer Schuhe hochladen und ihre Einzigartigkeit präsentieren.</p>
            </div>
            <div className="carousel-item">
            <h3>Intelligente Sortierung:</h3>
                <p> Schnelles Finden Ihrer Lieblingsschuhe mittels leistungsstarker Such- und Sortierfunktionen.</p>
            </div>
            <div className="carousel-item">
            <h3>Statusverfolgung:</h3>
                <p> Kennzeichnen Sie den Standort und Zustand Ihrer Schuhe – "Regal", "Lager", "Verkauft" oder "Entsorgt".</p>
            </div>
            <div className="carousel-item">
            <h3>Sicher und geschützt:</h3>
                <p> Authentifizierung für Vertrauen, Ihre Schuhdaten sind sicher bei uns aufgehoben.</p>
            </div>
            {/* Weitere Slides hier hinzufügen */}
        </Carousel>
    );
}

export default TextCarousel;
