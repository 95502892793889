import React, { useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import Logo1 from '../assets/Logo1.png';
import '../stylings/ImpressumStyling.css';
import CookieModal from '../components/CookieModal';


function Impressum() {

  useEffect(() => {

  }, [])

  return (
    <>
 <CookieModal></CookieModal>
      <Card id='card'>
        <Card.Header className="d-flex justify-content-between align-items-center menu-card-header">
          <div className="align-items-center" style={{ marginRight: 8 }}>
            <Image src={`${Logo1}`} style={{ width: 200 }} />
          </div>
          <a href="/" className="btn btn-primary">Home</a>
        </Card.Header>
        <Card.Body className="menu-card-body">
          <div className="background-image">

            <div className="impressum-card-content">
              <h2>Impressum</h2>
              <h3>Angaben gemäß § 5 TMG:</h3>
              <p>Justus Rademacher
                <br></br>Harzer Straße 10<br></br>12059 Berlin
              </p>
              <h3>Kontakt:</h3>
              <p><strong>Telefon:</strong>&nbsp;+49 (0) 176 80806968<br></br>
                <strong>E-Mail:</strong>&nbsp;justus.pokemon2017@gmail.com </p>

              <h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h3>
              <p>Justus Rademacher<br></br>Harzer Straße 10<br></br>12059 Berlin</p>
              <h3>Haftungsausschluss (Disclaimer)</h3>
              <p><strong>Haftung für Inhalte</strong></p>
              <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10
                TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
              <p><strong>Haftung für Links</strong></p>
              <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte
                auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
                nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
              <p><strong>Urheberrecht</strong></p>
              <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
                Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
                Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
           
              <p> <br></br> Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht
                ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
                ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
              </p>
            </div>

          </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center menu-card-footer">
          <p style={{ marginTop: 8, fontSize: 12, color: '#A1A1A1' }}>© 2023 Justus Rademacher</p>
          <p style={{ marginTop: 8, fontSize: 12, color: '#A1A1A1' }}><a href="/">Startseite</a> • <a href="/datenschutz">Datenschutz</a></p>
        </Card.Footer>
      </Card>

    </>

  );
}

export default Impressum;