import React, { useState, useEffect } from 'react';
import "../stylings/ScrollToTopButton.css";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    const contentDiv = document.querySelector('.wrapperDashboard'); // Replace with the actual class or selector of your content div
    if (contentDiv.scrollTop > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    const contentDiv = document.querySelector('.wrapperDashboard'); // Replace with the actual class or selector of your content div
    contentDiv.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const contentDiv = document.querySelector('.wrapperDashboard'); // Replace with the actual class or selector of your content div
    contentDiv.addEventListener('scroll', toggleVisibility);
    return () => {
      contentDiv.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <button
      id="scrollToTopButton"
      title="Scroll to Top"
      className={isVisible ? 'show' : 'hide'}
      onClick={scrollToTop}
    >
      ↑
    </button>
  );
};

export default ScrollToTopButton;
