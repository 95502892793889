import React, { useState, useEffect } from 'react';
import { Card, Image, Button, Modal, Form, FloatingLabel, Spinner } from 'react-bootstrap';
import imageCompression from 'browser-image-compression';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { useNavigate } from 'react-router-dom';

import '../stylings/DashboardStyling.css';

import Logo1 from '../assets/Logo1.png'
import FirestoreService from '../utils/services/FirestoreService';
import NotLoggedInView from '../components/NoLoggedInView';
import NotificationModal from '../components/NotificationModal';
import ErrorModal from '../components/ErrorModal';
import MyDataListComponent from '../components/MyDataListComponent';
import ScrollToTopButton from '../components/ScrollToTopButton';

import FilterIcon from '../assets/Filter.png';
import addIcon from '../assets/plus.png';
import logoutIcon from '../assets/logout.png';

import CookieModal from '../components/CookieModal';

function Admin(props) {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [userFromSelect, setUserFromSelect] = useState(null);
    const [allUser, setAllUser] = useState([]);
    const [schuhe, setSchuhe] = useState([]);
    const [allschuhe, setAllSchuhe] = useState([]);
    const [schuhKategorien, setSchuhKategorien] = useState([]);
    const [schuhStati, setSchuhStati] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [currentSchuh, setCurrentSchuh] = useState({
        "itemName": '',
        "itemCategory": '',
        "itemPrice": '',
        "itemSize": '',
        "itemModellNumber": '',
        "itemcolor": '',
        "itemBuyYear": '',
        "itemText": '',
        "imageUrl": '',
        "itemStati": ''
    });
    const [currentSchuhId, setCurrentSchuhId] = useState("");
    const [currentFilter, setCurrentFilter] = useState({
        "itemNameFilter": '',
        "itemCategoryFilter": '',
        "itemPriceFilter": '',
        "itemSizeFilter": '',
        "itemModellNumberFilter": '',
        "itemColorFilter": '',
        "itemBuyYearFilter": '',
        "itemStatiFilter": ''
    });

    const [showFilter, setShowFilter] = useState(false);

    const [showAddEditForm, setShowAddEditForm] = useState(false);
    const [addEditFormType, setAddEditFormType] = useState('Add');
    const [validated, setValidated] = useState(false);

    const [showDeleteDialogue, setShowDeleteDialogue] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [authChecking, setAuthChecking] = useState(true);

    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            setUser(user);
        } else {
            setUser(null);
        }
        setAuthChecking(false); // Set it to false after checking auth status
    });

    function fetchSchuhStati() {
        setIsLoading(true);
        FirestoreService.getAllSchuhStati().then((response) => {
            setSchuhStati(response._delegate._snapshot.docChanges);
        })
            .catch((e) => {
                handleShowConfirmation("Beim Abrufen der Schuhstati ist ein Fehler aufgetreten: " + e.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function fetchAllUser() {
        setIsLoading(true);
        FirestoreService.getAllUser().then((response) => {
            setAllUser(response._delegate._snapshot.docChanges);
        })
            .catch((e) => {
                navigate('/dashboard');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function fetchSchuhKategorien() {
        setIsLoading(true);
        FirestoreService.getAllSchuhKategorien().then((response) => {
            setSchuhKategorien(response._delegate._snapshot.docChanges);
        })
            .catch((e) => {
                handleShowConfirmation("Beim Abrufen der Schuhkategorien ist ein Fehler aufgetreten: " + e.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function fetchSchuhe(selectedUser) {
        setIsLoading(true);
        FirestoreService.getAllSchuhe(selectedUser.doc.data.value.mapValue.fields.uid.stringValue)
            .then((response) => {
                setSchuhe(response._delegate._snapshot.docChanges);
                setAllSchuhe(response._delegate._snapshot.docChanges);
            })
            .catch((e) => {
                handleShowConfirmation("Beim Abrufen des Schuhs ist ein Fehler aufgetreten: " + e.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (user !== null) {
            if (allUser.length <= 0) {
                fetchAllUser();
            }
            if (schuhKategorien.length <= 0) {
                fetchSchuhKategorien();
            }
            if (schuhStati.length <= 0) {
                fetchSchuhStati();
            }
        }
        if (userFromSelect !== null) {
            fetchSchuhe(userFromSelect);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const handleModalClose = () => {
        setShowAddEditForm(false);
        setShowDeleteDialogue(false);
        setShowFilter(false);
        setCurrentSchuhId("");
        setAddEditFormType("Add");
        setCurrentSchuh({ "itemName": '', "itemCategory": '', "itemPrice": '', "itemSize": '', "itemModellNumber": '', "itemcolor": '', "itemBuyYear": '', "itemText": '', "itemStati": '' })
        setIsLoading(false);
        setValidated(false);
        setImage(null);
    }

    const handleDeleteModalClose = () => {
        setShowDeleteDialogue(false);
    }

    const resetFilter = () => {
        setCurrentFilter({
            itemName: '',
            itemCategory: '',
            itemPrice: '',
            itemSize: '',
            itemModellNumber: '',
            itemcolor: '',
            itemBuyYear: '',
            itemStati: ''
        });
    };

    const handleFilterClose = () => {
        setSchuhe(allschuhe)
        resetFilter();
        handleModalClose()
    }

    const handleShowConfirmation = (message) => {
        setConfirmationMessage(message);
        setShowConfirmationModal(true);
    };

    const handleErrorNotification = (message) => {
        setErrorMessage(message);
        setShowErrorModal(true);
    };

    const handleAddEditFormSubmit = async (e) => {
        e.preventDefault();
        const { itemName, itemCategory, itemPrice, itemSize, itemModellNumber, itemcolor, itemBuyYear, itemText, itemStati } = e.target.elements;
        setValidated(true);
        if (itemBuyYear.value.length === 4 || itemBuyYear.value === "") {
            if (itemName.value && itemSize.value && itemModellNumber.value) {
                try {
                    setIsLoading(true);
                    if (addEditFormType === "Add" && image) {
                        await addNewSchuh(itemName, itemCategory, itemPrice, itemSize, itemModellNumber, itemcolor, itemBuyYear, itemText, itemStati);
                    } else if (addEditFormType === "Edit") {
                        await editSchuh(itemName, itemCategory, itemPrice, itemSize, itemModellNumber, itemcolor, itemBuyYear, itemText, itemStati);
                    } else {
                        handleErrorNotification(`Bitte Bild auswählen`);
                    }
                } catch (error) {
                    handleErrorNotification(`Ein Fehler trat auf:: ${error.message}`);
                } finally {
                    setIsLoading(false);
                }
            } else {
                handleErrorNotification(`Bitte alle erforderlichen Felder ausfüllen`);
            }
        } else {
            handleErrorNotification(`Kaufjahr 4-Stellig oder leer`);
        }
    };

    const addNewSchuh = async (itemName, itemCategory, itemPrice, itemSize, itemModellNumber, itemcolor, itemBuyYear, itemText, itemStati) => {
        const schuhDoc = await FirestoreService.AddNewSchuh(userFromSelect.doc.data.value.mapValue.fields.uid.stringValue, itemName.value, itemCategory.value, itemPrice.value, itemSize.value, itemModellNumber.value, itemcolor.value, itemBuyYear.value, itemText.value, itemStati.value);
        const schuhDocId = schuhDoc.id;
        const imageUrl = await FirestoreService.handleImageUpload(userFromSelect.doc.data.value.mapValue.fields.uid.stringValue, image, schuhDocId);
        await FirestoreService.UpdateSchuh(userFromSelect.doc.data.value.mapValue.fields.uid.stringValue, schuhDocId, itemName.value, itemCategory.value, itemPrice.value, itemSize.value, itemModellNumber.value, itemcolor.value, itemBuyYear.value, itemText.value, imageUrl, itemStati.value);
        handleShowConfirmation(`${itemName.value} wurde hinzugefügt.`);
        handleModalClose();
        fetchSchuhe(userFromSelect);
        setValidated(false);
        resetFilter();
    };
    
    const editSchuh = async (itemName, itemCategory, itemPrice, itemSize, itemModellNumber, itemcolor, itemBuyYear, itemText, itemStati) => {
        const imageUrl = image ? await FirestoreService.handleImageUpload(userFromSelect.doc.data.value.mapValue.fields.uid.stringValue, image, currentSchuhId) : currentSchuh.imageUrl;
        await FirestoreService.UpdateSchuh(userFromSelect.doc.data.value.mapValue.fields.uid.stringValue, currentSchuhId, itemName.value, itemCategory.value, itemPrice.value, itemSize.value, itemModellNumber.value, itemcolor.value, itemBuyYear.value, itemText.value, imageUrl, itemStati.value);
        handleShowConfirmation(`${itemName.value} wurde aktualisiert.`);
        handleModalClose();
        fetchSchuhe(userFromSelect);
        setValidated(false);
        resetFilter();
    };

    const handleSchuhDelete = async () => {
        try {
            setIsLoading(true);
            await FirestoreService.DeleteSchuh(userFromSelect.doc.data.value.mapValue.fields.uid.stringValue, currentSchuhId);
            const nutzerUID = userFromSelect.doc.data.value.mapValue.fields.uid.stringValue;
            const storageRef = firebase.storage().ref();
            const imageRef = storageRef.child(`images/${nutzerUID}/schuhe/${currentSchuhId}`);
            await imageRef.delete();
            handleShowConfirmation(`Schuh wurde gelöscht.`);
            handleModalClose();
            fetchSchuhe(userFromSelect);
            resetFilter();
        } catch (e) {
            handleShowConfirmation(`Ein Fehler beim Löschen trat auf: ${e.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const LogoutButtonPressed = () => {
        firebase.auth().signOut().then(() => {
            //Signout Successful
            handleShowConfirmation("Logout erfolgreich.");
            setUser(null);
            setValidated(false);
            setTimeout(() => {
                navigate('/'); // Navigiert zum Dashboard nach einer Verzögerung von 2 Sekunden
            }, 2000);
        }).catch((e) => {
            handleShowConfirmation("Ein Fehler trat auf:: " + e.message);
        })
    }

    const handleImageChange = async (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            setLoading(true); // Start loading
            try {
                const options = {
                    maxSizeMB: 0.5, // Adjust this value to control the maximum size in MB
                    maxWidthOrHeight: 700, // Resize image dimensions
                    useWebWorker: true,
                    initialQuality: 1, // Initial quality setting
                };
                // Compress and resize the image
                const compressedImage = await imageCompression(selectedImage, options);
                // Set the compressed image in the state
                setImage(compressedImage);
                // Read the compressed image for preview
                const reader = new FileReader();
                reader.onloadend = () => {
                    setCurrentSchuh((prevSchuh) => ({
                        ...prevSchuh,
                        imageUrl: reader.result,
                    }));
                    setLoading(false); // Stop loading when done
                };
                reader.readAsDataURL(compressedImage);
            } catch (error) {
                console.error('Error compressing image:', error);
                setLoading(false); // Stop loading when done
            }
        } else {
            setCurrentSchuh((prevSchuh) => ({
                ...prevSchuh,
                imageUrl: '',
            }));
            setLoading(false); // Stop loading when done
        }
    };

    const handleFilterFormSubmit = (e) => {
        const gefilterteSchuhe = filterShoes(allschuhe, currentFilter);
        setSchuhe(gefilterteSchuhe)
        setShowFilter(false);
    }

    const filterShoes = (shoes, filters) => {
        return shoes.filter((shoe) => {
            // Filterbedingungen überprüfen          
            const {
                itemName: { stringValue: itemName },
                itemCategory: { stringValue: itemCategory },
                itemPrice,
                itemSize: { stringValue: itemSize },
                itemModellNumber: { stringValue: itemModellNumber },
                itemcolor: { stringValue: itemColor },
                itemBuyYear: { integerValue: itemBuyYear },
                itemStati: { stringValue: itemStati }
            } = shoe.doc.data.value.mapValue.fields;
            // Hier die Überprüfung, ob itemPrice doubleValue oder integerValue ist
            let itemPriceValue;
            if (itemPrice.doubleValue) {
                itemPriceValue = itemPrice.doubleValue;
            } else if (itemPrice.integerValue) {
                itemPriceValue = itemPrice.integerValue;
            } else {
                // Fallback-Wert, falls keines der Felder vorhanden ist oder keine Zahl ist
                itemPriceValue = null;
            }
            // Filterbedingungen aus der Eingabemaske
            const {
                itemNameFilter,
                itemCategoryFilter,
                itemPriceFilter,
                itemSizeFilter,
                itemModellNumberFilter,
                itemColorFilter,
                itemBuyYearFilter,
                itemStatiFilter
            } = filters;

            const isItemNameMatched = itemName.includes(itemNameFilter) || itemNameFilter === undefined;
            const isItemCategoryMatched = itemCategory.includes(itemCategoryFilter) || itemCategoryFilter === undefined;
            // eslint-disable-next-line eqeqeq
            const isItemPriceMatched = Number(itemPriceValue) === Number(itemPriceFilter) || itemPriceFilter === undefined;
            const isItemSizeMatched = itemSize.includes(itemSizeFilter) || itemSizeFilter === undefined;
            const isItemModellNumberMatched = itemModellNumber.includes(itemModellNumberFilter) || itemModellNumberFilter === undefined;
            const isItemColorMatched = itemColor.includes(itemColorFilter) || itemColorFilter === undefined;
            // eslint-disable-next-line eqeqeq
            const isItemBuyYearMatched = itemBuyYear == itemBuyYearFilter || itemBuyYearFilter === undefined;
            const isItemStatiMatched = itemStati.includes(itemStatiFilter) || itemStatiFilter === undefined;
            /* console.log(
                 itemName + " " + itemNameFilter + " " + isItemNameMatched + " \n" +
                 itemCategory + " " + itemCategoryFilter + " " + isItemCategoryMatched + " \n" +
                 itemPriceValue + " " + itemPriceFilter + " " + isItemPriceMatched + " \n" +
                 itemSize + " " + itemSizeFilter + " " + isItemSizeMatched + " \n" +
                 itemModellNumber + " " + itemModellNumberFilter + " " + isItemModellNumberMatched + " \n" +
                 itemColor + " " + itemColorFilter + " " + isItemColorMatched + " \n" +
                 itemBuyYear + " " + itemBuyYearFilter + " \n" + isItemBuyYearMatched + " \n"
             )  */
            // Gib zurück, ob alle Filterbedingungen erfüllt sind (kombiniert mit && oder || je nach Bedarf)
            return (
                isItemNameMatched &&
                isItemCategoryMatched &&
                isItemPriceMatched &&
                isItemSizeMatched &&
                isItemModellNumberMatched &&
                isItemColorMatched &&
                isItemBuyYearMatched &&
                isItemStatiMatched
            );
        });
    };

    // Funktion zum Verarbeiten des Userselects
    const handleUserSelect = (selectedUser) => {
        fetchSchuhe(selectedUser);
    }

    return (
        <>
            <CookieModal></CookieModal>
            {/* <h1>You're not logged in. Please <a href="/login">login</a> first then come to dashboard.</h1> */}
            {(user === null && authChecking===true) && <div>Loading...</div>}
            {(user === null && authChecking===false) && <NotLoggedInView />}
            {(user !== null) && <>

                {/* Filter Form START */}
                <Modal show={showFilter} onHide={handleModalClose}>
                    <Form noValidate>
                        <Modal.Header closeButton>
                            <Modal.Title>Setze deine Filter</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FloatingLabel controlId="itemNameFilter" label="Schuhname" className="mb-3" >
                                <Form.Control type='text' placeholder='Schuhname' size='md' value={currentFilter?.itemNameFilter} onChange={(e) => {
                                    setCurrentFilter({
                                        "itemNameFilter": (e.target.value) ? e.target.value : '',
                                        "itemCategoryFilter": currentFilter?.itemCategoryFilter,
                                        "itemPriceFilter": currentFilter?.itemPriceFilter,
                                        "itemSizeFilter": currentFilter?.itemSizeFilter,
                                        "itemModellNumberFilter": currentFilter?.itemModellNumberFilter,
                                        "itemColorFilter": currentFilter?.itemColorFilter,
                                        "itemBuyYearFilter": currentFilter?.itemBuyYearFilter,
                                        "itemStatiFilter": currentFilter?.itemStatiFilter,
                                    })
                                }} />
                            </FloatingLabel>

                            <FloatingLabel controlId="itemCategoryFilter" label="Schuhmarke" className="mb-3" >
                                <Form.Select value={currentFilter?.itemCategoryFilter || ""} onChange={(e) => {
                                    setCurrentFilter({
                                        "itemNameFilter": currentFilter?.itemNameFilter,
                                        "itemCategoryFilter": e.target.value,
                                        "itemPriceFilter": currentFilter?.itemPriceFilter,
                                        "itemSizeFilter": currentFilter?.itemSizeFilter,
                                        "itemModellNumberFilter": currentFilter?.itemModellNumberFilter,
                                        "itemColorFilter": currentFilter?.itemColorFilter,
                                        "itemBuyYearFilter": currentFilter?.itemBuyYearFilter,
                                        "itemStatiFilter": currentFilter?.itemStatiFilter,
                                    })
                                }}>
                                    <option value="">--- Keine Auswahl ---</option>
                                    {(schuhKategorien) && (schuhKategorien.map((menuCategory, index) => (
                                        <option key={index} value={menuCategory.doc.data.value.mapValue.fields.catName.stringValue}>
                                            {menuCategory.doc.data.value.mapValue.fields.catName.stringValue}</option>
                                    )))}
                                </Form.Select>
                            </FloatingLabel>

                            <FloatingLabel controlId="itemModellNumberFilter" label="Schuhmodellnummer" className="mb-3" >
                                <Form.Control type='text' placeholder='Schuhmodellnummer' size='md' value={currentFilter?.itemModellNumberFilter} onChange={(e) => {
                                    setCurrentFilter({
                                        "itemNameFilter": currentFilter?.itemNameFilter,
                                        "itemCategoryFilter": currentFilter?.itemCategoryFilter,
                                        "itemPriceFilter": currentFilter?.itemPriceFilter,
                                        "itemSizeFilter": currentFilter?.itemSizeFilter,
                                        "itemModellNumberFilter": (e.target.value) ? e.target.value : '',
                                        "itemColorFilter": currentFilter?.itemColorFilter,
                                        "itemBuyYearFilter": currentFilter?.itemBuyYearFilter,
                                        "itemStatiFilter": currentFilter?.itemStatiFilter,
                                    })
                                }} />
                            </FloatingLabel>

                            <FloatingLabel controlId="itemcolorFilter" label="Schuhfarbe" className="mb-3" >
                                <Form.Control type='text' placeholder='Schuhfarbe' size='md' value={currentFilter?.itemColorFilter} onChange={(e) => {
                                    setCurrentFilter({
                                        "itemNameFilter": currentFilter?.itemNameFilter,
                                        "itemCategoryFilter": currentFilter?.itemCategoryFilter,
                                        "itemPriceFilter": currentFilter?.itemPriceFilter,
                                        "itemSizeFilter": currentFilter?.itemSizeFilter,
                                        "itemModellNumberFilter": currentFilter?.itemModellNumberFilter,
                                        "itemColorFilter": (e.target.value) ? e.target.value : '',
                                        "itemBuyYearFilter": currentFilter?.itemBuyYearFilter,
                                        "itemStatiFilter": currentFilter?.itemStatiFilter,
                                    })
                                }} />
                            </FloatingLabel>

                            <FloatingLabel controlId="itemSizeFilter" label="Schuhgröße" className="mb-3" >
                                <Form.Control type='text' placeholder='Schuhgröße' size='md' value={currentFilter?.itemSizeFilter} onChange={(e) => {
                                    setCurrentFilter({
                                        "itemNameFilter": currentFilter?.itemNameFilter,
                                        "itemCategoryFilter": currentFilter?.itemCategoryFilter,
                                        "itemPriceFilter": currentFilter?.itemPriceFilter,
                                        "itemSizeFilter": e.target.value,
                                        "itemModellNumberFilter": currentFilter?.itemModellNumberFilter,
                                        "itemColorFilter": currentFilter?.itemColorFilter,
                                        "itemBuyYearFilter": currentFilter?.itemBuyYearFilter,
                                        "itemStatiFilter": currentFilter?.itemStatiFilter,
                                    })
                                }} />
                            </FloatingLabel>

                            <FloatingLabel controlId="itemPriceFilter" label="Kaufpreis (€)" className="mb-3">
                                <Form.Control type='number' placeholder='0' size='md' value={currentFilter?.itemPriceFilter} onChange={(e) => {
                                    setCurrentFilter({
                                        "itemNameFilter": currentFilter?.itemNameFilter,
                                        "itemCategoryFilter": currentFilter?.itemCategoryFilter,
                                        "itemPriceFilter": e.target.value,
                                        "itemSizeFilter": currentFilter?.itemSizeFilter,
                                        "itemModellNumberFilter": currentFilter?.itemModellNumberFilter,
                                        "itemColorFilter": currentFilter?.itemColorFilter,
                                        "itemBuyYearFilter": currentFilter?.itemBuyYearFilter,
                                        "itemStatiFilter": currentFilter?.itemStatiFilter,
                                    })
                                }} />
                            </FloatingLabel>

                            <FloatingLabel controlId="itemBuyYearFilter" label="Kaufjahr" className="mb-3">
                                <Form.Control type='number' placeholder='Kaufjahr' value={currentFilter?.itemBuyYearFilter} onChange={(e) => {
                                    setCurrentFilter({
                                        "itemNameFilter": currentFilter?.itemNameFilter,
                                        "itemCategoryFilter": currentFilter?.itemCategoryFilter,
                                        "itemPriceFilter": currentFilter?.itemPriceFilter,
                                        "itemSizeFilter": currentFilter?.itemSizeFilter,
                                        "itemModellNumberFilter": currentFilter?.itemModellNumberFilter,
                                        "itemColorFilter": currentFilter?.itemColorFilter,
                                        "itemBuyYearFilter": e.target.value,
                                        "itemStatiFilter": currentFilter?.itemStatiFilter,
                                    })
                                }} />
                            </FloatingLabel>

                            <FloatingLabel controlId="itemStatiFilter" label="Status" className="mb-3" >
                                <Form.Select value={currentFilter?.itemStatiFilter || ""} onChange={(e) => {
                                    setCurrentFilter({
                                        "itemNameFilter": currentFilter?.itemNameFilter,
                                        "itemCategoryFilter": currentFilter?.itemCategoryFilter,
                                        "itemPriceFilter": currentFilter?.itemPriceFilter,
                                        "itemSizeFilter": currentFilter?.itemSizeFilter,
                                        "itemModellNumberFilter": currentFilter?.itemModellNumberFilter,
                                        "itemColorFilter": currentFilter?.itemColorFilter,
                                        "itemBuyYearFilter": currentFilter?.itemBuyYearFilter,
                                        "itemStatiFilter": e.target.value,
                                    })
                                }}>
                                    <option value="">--- Keine Auswahl ---</option>
                                    {(schuhStati) && (schuhStati.map((menuStati, index) => (
                                        <option key={index} value={menuStati.doc.data.value.mapValue.fields.statiName.stringValue}>
                                            {menuStati.doc.data.value.mapValue.fields.statiName.stringValue}</option>
                                    )))}
                                </Form.Select>
                            </FloatingLabel>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <Button variant="secondary" onClick={handleModalClose}>
                                Abbrechen
                            </Button>
                            <Button variant="danger" onClick={handleFilterClose}>Filter löschen</Button>
                            <Button variant='primary' onClick={handleFilterFormSubmit}>Filter anwenden</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                {/* Filter Form END */}

                {/* Error Modal START */}
                {showErrorModal && (
                    <ErrorModal
                        message={errorMessage}
                        onClose={() => setShowErrorModal(false)}
                    />
                )}
                {/* Error Modal END */}

                {/* Confirmation Modal START */}
                {showConfirmationModal && (
                    <NotificationModal
                        message={confirmationMessage}
                        onClose={() => setShowConfirmationModal(false)}
                    />
                )}
                {/* Confirmation Modal END */}

                {/* Add/Edit Form START */}
                <Modal show={showAddEditForm} onHide={handleModalClose}>
                    <Form noValidate validated={validated} onSubmit={handleAddEditFormSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>{(addEditFormType === 'Add') ? 'Schuh hinzufügen' : 'Schuh bearbeiten'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <FloatingLabel controlId="itemName" label="Schuhname" className="mb-3" >
                                    <Form.Control required type='text' placeholder='Schuhname' size='md' value={currentSchuh?.itemName} onChange={(e) => {
                                        setCurrentSchuh({
                                            "itemName": (e.target.value) ? e.target.value : '',
                                            "itemCategory": currentSchuh?.itemCategory,
                                            "itemPrice": currentSchuh?.itemPrice,
                                            "itemSize": currentSchuh?.itemSize,
                                            "itemModellNumber": currentSchuh?.itemModellNumber,
                                            "itemcolor": currentSchuh?.itemcolor,
                                            "itemBuyYear": currentSchuh?.itemBuyYear,
                                            "itemText": currentSchuh?.itemText,
                                            "imageUrl": currentSchuh?.imageUrl,
                                            "itemStati": currentSchuh?.itemStati,
                                        })
                                    }} />
                                    <Form.Control.Feedback type='invalid'>Schuhname ist notwendig.</Form.Control.Feedback>
                                </FloatingLabel>

                                <FloatingLabel controlId="itemCategory" label="Schuhmarke" className="mb-3" >
                                    <Form.Select value={currentSchuh?.itemCategory} onChange={(e) => {
                                        setCurrentSchuh({
                                            "itemName": currentSchuh?.itemName,
                                            "itemCategory": e.target.value,
                                            "itemPrice": currentSchuh?.itemPrice,
                                            "itemSize": currentSchuh?.itemSize,
                                            "itemModellNumber": currentSchuh?.itemModellNumber,
                                            "itemcolor": currentSchuh?.itemcolor,
                                            "itemBuyYear": currentSchuh?.itemBuyYear,
                                            "itemText": currentSchuh?.itemText,
                                            "imageUrl": currentSchuh?.imageUrl,
                                            "itemStati": currentSchuh?.itemStati,
                                        })
                                    }}>
                                        {(schuhKategorien) && (schuhKategorien.map((menuCategory, index) => (
                                            // catNum.integerValue
                                            <option key={index} value={menuCategory.doc.data.value.mapValue.fields.catName.stringValue}>
                                                {menuCategory.doc.data.value.mapValue.fields.catName.stringValue}</option>
                                        )))}
                                    </Form.Select>
                                </FloatingLabel>

                                <FloatingLabel controlId="itemModellNumber" label="Schuhmodellnummer" className="mb-3" >
                                    <Form.Control required type='text' placeholder='Schuhmodellnummer' size='md' value={currentSchuh?.itemModellNumber} onChange={(e) => {
                                        setCurrentSchuh({
                                            "itemName": currentSchuh?.itemName,
                                            "itemCategory": currentSchuh?.itemCategory,
                                            "itemPrice": currentSchuh?.itemPrice,
                                            "itemSize": currentSchuh?.itemSize,
                                            "itemModellNumber": (e.target.value) ? e.target.value : '',
                                            "itemcolor": currentSchuh?.itemcolor,
                                            "itemBuyYear": currentSchuh?.itemBuyYear,
                                            "itemText": currentSchuh?.itemText,
                                            "imageUrl": currentSchuh?.imageUrl,
                                            "itemStati": currentSchuh?.itemStati,
                                        })
                                    }} />
                                    <Form.Control.Feedback type='invalid'>Schuhmodellnummer ist notwendig.</Form.Control.Feedback>
                                </FloatingLabel>

                                <FloatingLabel controlId="itemcolor" label="Schuhfarbe" className="mb-3" >
                                    <Form.Control type='text' placeholder='Schuhfarbe' size='md' value={currentSchuh?.itemcolor} onChange={(e) => {
                                        setCurrentSchuh({
                                            "itemName": currentSchuh?.itemName,
                                            "itemCategory": currentSchuh?.itemCategory,
                                            "itemPrice": currentSchuh?.itemPrice,
                                            "itemSize": currentSchuh?.itemSize,
                                            "itemModellNumber": currentSchuh?.itemModellNumber,
                                            "itemcolor": (e.target.value) ? e.target.value : '',
                                            "itemBuyYear": currentSchuh?.itemBuyYear,
                                            "itemText": currentSchuh?.itemText,
                                            "imageUrl": currentSchuh?.imageUrl,
                                            "itemStati": currentSchuh?.itemStati,
                                        })
                                    }} />
                                </FloatingLabel>

                                <FloatingLabel controlId="itemSize" label="Schuhgröße" className="mb-3" >
                                    <Form.Control required type='text' placeholder='Schuhgröße' size='md' value={currentSchuh?.itemSize} onChange={(e) => {
                                        setCurrentSchuh({
                                            "itemName": currentSchuh?.itemName,
                                            "itemCategory": currentSchuh?.itemCategory,
                                            "itemPrice": currentSchuh?.itemPrice,
                                            "itemSize": e.target.value,
                                            "itemModellNumber": currentSchuh?.itemModellNumber,
                                            "itemcolor": currentSchuh?.itemcolor,
                                            "itemBuyYear": currentSchuh?.itemBuyYear,
                                            "itemText": currentSchuh?.itemText,
                                            "imageUrl": currentSchuh?.imageUrl,
                                            "itemStati": currentSchuh?.itemStati,
                                        })
                                    }} />
                                    <Form.Control.Feedback type='invalid'>Schuhgröße ist notwendig.</Form.Control.Feedback>
                                </FloatingLabel>

                                <FloatingLabel controlId="itemPrice" label="Kaufpreis (€)" className="mb-3">
                                    <Form.Control type='number' placeholder='0' size='md' value={currentSchuh?.itemPrice} onChange={(e) => {
                                        setCurrentSchuh({
                                            "itemName": currentSchuh?.itemName,
                                            "itemCategory": currentSchuh?.itemCategory,
                                            "itemPrice": e.target.value,
                                            "itemSize": currentSchuh?.itemSize,
                                            "itemModellNumber": currentSchuh?.itemModellNumber,
                                            "itemcolor": currentSchuh?.itemcolor,
                                            "itemBuyYear": currentSchuh?.itemBuyYear,
                                            "itemText": currentSchuh?.itemText,
                                            "imageUrl": currentSchuh?.imageUrl,
                                            "itemStati": currentSchuh?.itemStati,
                                        })
                                    }} />
                                </FloatingLabel>

                                <FloatingLabel controlId="itemBuyYear" label="Kaufjahr" className="mb-3">
                                    <Form.Control type='number' placeholder='Kaufjahr' value={currentSchuh?.itemBuyYear} onChange={(e) => {
                                        setCurrentSchuh({
                                            "itemName": currentSchuh?.itemName,
                                            "itemCategory": currentSchuh?.itemCategory,
                                            "itemPrice": currentSchuh?.itemPrice,
                                            "itemSize": currentSchuh?.itemSize,
                                            "itemModellNumber": currentSchuh?.itemModellNumber,
                                            "itemcolor": currentSchuh?.itemcolor,
                                            "itemBuyYear": e.target.value,
                                            "itemText": currentSchuh?.itemText,
                                            "imageUrl": currentSchuh?.imageUrl,
                                            "itemStati": currentSchuh?.itemStati,
                                        })
                                    }} />
                                </FloatingLabel>

                                <FloatingLabel controlId="itemText" label='Freitext' className="mb-3" >
                                    <Form.Control as="textarea" placeholder='Freitext' maxLength={100} style={{
                                        minHeight: '100px'
                                    }} size='md' value={currentSchuh?.itemText} onChange={(e) => {
                                        setCurrentSchuh({
                                            "itemName": currentSchuh?.itemName,
                                            "itemCategory": currentSchuh?.itemCategory,
                                            "itemPrice": currentSchuh?.itemPrice,
                                            "itemSize": currentSchuh?.itemSize,
                                            "itemModellNumber": currentSchuh?.itemModellNumber,
                                            "itemcolor": currentSchuh?.itemcolor,
                                            "itemBuyYear": currentSchuh?.itemBuyYear,
                                            "itemText": (e.target.value) ? e.target.value : '',
                                            "imageUrl": currentSchuh?.imageUrl,
                                            "itemStati": currentSchuh?.itemStati,
                                        })
                                    }} />
                                    <div style={{ fontSize: '12px', color: '#6c757d' }}>
                                        {/* Display remaining character count */}
                                        {100 - currentSchuh?.itemText?.length} Zeichen übrig
                                    </div>
                                </FloatingLabel>

                                <FloatingLabel controlId="itemStati" label="Status" className="mb-3" >
                                    <Form.Select value={currentSchuh?.itemStati || ""} onChange={(e) => {
                                        setCurrentSchuh({
                                            "itemName": currentSchuh?.itemName,
                                            "itemCategory": currentSchuh?.itemCategory,
                                            "itemPrice": currentSchuh?.itemPrice,
                                            "itemSize": currentSchuh?.itemSize,
                                            "itemModellNumber": currentSchuh?.itemModellNumber,
                                            "itemcolor": currentSchuh?.itemcolor,
                                            "itemBuyYear": currentSchuh?.itemBuyYear,
                                            "itemText": currentSchuh?.itemText,
                                            "imageUrl": currentSchuh?.imageUrl,
                                            "itemStati": e.target.value,
                                        })
                                    }}>
                                        <option value="">--- Keine Auswahl ---</option>
                                        {(schuhStati) && (schuhStati.map((menuStati, index) => (
                                            <option key={index} value={menuStati.doc.data.value.mapValue.fields.statiName.stringValue}>
                                                {menuStati.doc.data.value.mapValue.fields.statiName.stringValue}</option>
                                        )))}
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <label htmlFor="imageUpload" className="custom-file-upload">
                                    Datei auswählen
                                </label>
                                <input
                                    id="imageUpload"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                />
                                {loading ? (
                                        <div className="loader">Bild wird verarbeitet...</div>
    ) : (
        currentSchuh?.imageUrl ? (
            <img src={currentSchuh?.imageUrl} alt="Uploaded" style={{ maxWidth: '300px', marginTop: '10px' }} />
        ) : null
    )}                            
                                <style>{`
                                    .custom-file-upload {
                                    display: inline-block;
                                    padding: 6px 12px;
                                    cursor: pointer;
                                    background-color: #ddd;
                                    border: none;
                                    color: #444;
                                    border-radius: 4px;
                                    font-size: 14px;
                                    transition: background-color 0.2s ease-in-out;
                                    }
                                    
                                    .custom-file-upload:hover {
                                    background-color: #ccc;
                                    }

                                    .loader {
                                        font-size: 16px;
                                        color: #888;
                                        margin-top: 10px;
                                    }
                                `}</style>
                            </div>
                        </Modal.Body>
                        {addEditFormType === 'Add' && (
                            <Modal.Footer>
                                <Button type="submit">{addEditFormType === 'Add' ? 'Add' : 'Update'}</Button>
                            </Modal.Footer>
                        )}
                        {addEditFormType === 'Edit' && (
                            <Modal.Footer className="d-flex justify-content-between">
                                <Button variant="danger" onClick={() => setShowDeleteDialogue(true)}>
                                    🗑 Löschen
                                </Button>
                                <div>
                                    <Button type="submit">{addEditFormType === 'Add' ? 'Add' : 'Update'}</Button>
                                </div>
                            </Modal.Footer>
                        )}

                    </Form>
                </Modal>
                {/* Add/Edit Form END */}

                {/* Delete Confirmation Dialogue START */}
                <Modal show={showDeleteDialogue} onHide={handleDeleteModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Lösche {currentSchuh.itemName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Bist du dir sicher, dass du {currentSchuh.itemName} löschen möchtest?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleDeleteModalClose}>
                            Abbrechen
                        </Button>
                        <Button variant="danger" onClick={handleSchuhDelete}>
                            Ja, Löschen
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Delete Confirmation Dialogue END */}

                <Card id="DashboardCard">
                    <div id='cardDashBoard'>
                        <Card.Header className="d-flex justify-content-between align-items-center Dashboard-card-header">
                            <div className="align-items-center" style={{ marginRight: 8 }}>
                                <Image src={`${Logo1}`} style={{ width: 150 }} />
                                <h4 style={{ marginTop: 8, }}>Adminspace</h4>
                            </div>
                            <Button style={{ backgroundColor: '#0001', color: 'black', borderWidth: 0, fontWeight: 'bold', display: 'flex', alignItems: 'center' }} onClick={() => { LogoutButtonPressed() }
                            }>
                                <img src={logoutIcon} alt="Logout" style={{ /* marginRight: '0.5em', */height: '1.2em' }} />
                                {/* <span>Logout</span> */}
                            </Button>
                            {(allUser && allUser.length > 0) && <>
                                <Button style={{ backgroundColor: '#0001', color: 'black', borderWidth: 0, fontWeight: 'bold', display: 'flex', alignItems: 'center' }} onClick={() => { setShowFilter(true) }
                                }>
                                    <img src={FilterIcon} alt="Filter" style={{ /* marginRight: '0.5em', */height: '1.2em' }} />
                                    {/* <span>Filter</span> */}
                                </Button>
                                <Button style={{ backgroundColor: '#0001', color: 'black', borderWidth: 0, fontWeight: 'bold', display: 'flex', alignItems: 'center' }} onClick={() => {
                                    setShowAddEditForm(true);
                                }}> <img src={addIcon} alt="Hinzufügen" style={{ /* marginRight: '0.5em', */height: '1.2em' }} />
                                    {/* <span>Schuh</span> */}
                                </Button>
                            </>}

                        </Card.Header>
                        <div className="wrapperDashboard">
                            <Card.Body className="Dashboard-card-body">
                                {isLoading === true && (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                        <Spinner animation="border" variant="secondary" style={{ width: '5rem', height: '5rem' }} />
                                    </div>
                                )}
                                {isLoading === false && allUser && allUser.length > 0 && (
                                    <div>
                                        <Form noValidate>
                                            <FloatingLabel controlId="userSelect" label="User" className="mb-3" >
                                                <Form.Select
                                                    value={allUser && userFromSelect ? allUser.indexOf(userFromSelect) : ""}
                                                    onChange={(e) => {
                                                        if (e.target.value === "") {
                                                            setUserFromSelect(null);
                                                        } else {
                                                            const selectedUser = allUser[e.target.value];
                                                            setUserFromSelect(selectedUser);
                                                            handleUserSelect(selectedUser);
                                                        }
                                                    }}>
                                                    <option value="">--- Keine Auswahl ---</option>
                                                    {(allUser) && (allUser.map((selectUser, index) => (
                                                        <option key={index} value={index}>
                                                            {selectUser.doc.data.value.mapValue.fields.name.stringValue}
                                                        </option>
                                                    )))}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Form>
                                        <span>{userFromSelect && userFromSelect.doc.data.value.mapValue.fields.name.stringValue} </span>
                                        <span>{userFromSelect && userFromSelect.doc.data.value.mapValue.fields.uid.stringValue}</span>
                                    </div>
                                )}
                                {schuhe && schuhe.length > 0 && isLoading === false && userFromSelect !== null && (
                                    <MyDataListComponent
                                        schuhe={schuhe}
                                        setCurrentSchuhId={setCurrentSchuhId}
                                        setCurrentSchuh={setCurrentSchuh}
                                        setAddEditFormType={setAddEditFormType}
                                        setShowAddEditForm={setShowAddEditForm}
                                        setShowDeleteDialogue={setShowDeleteDialogue}
                                    />
                                )}
                                 {(allUser && allUser.length === 0) && <>
                                <p>Sie haben keinen Zugriff auf die Adminseite. <br></br>Bitte kehren Sie zum <a href='/dashboard'>Dashboard</a>  zurück.</p>
                            </>}
                            </Card.Body>
                            <Card.Footer className="d-flex justify-content-between align-items-center">
                                <p style={{ marginTop: 8, fontSize: 12, color: '#A1A1A1' }}><a href="/" onClick={
                                    LogoutButtonPressed
                                }>Ausloggen</a></p>

                                <p style={{ marginTop: 8, fontSize: 12, color: '#A1A1A1' }}><a href="/impressum">Impressum</a> • <a href="/datenschutz">Datenschutz</a></p>
                            </Card.Footer>
                            <ScrollToTopButton />
                        </div>
                    </div>
                </Card>

            </>}
        </>
    );
}

export default Admin;