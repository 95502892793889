import React, { useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import Logo1 from '../assets/Logo1.png';
import '../stylings/DatenschutzStyling.css';
import CookieModal from '../components/CookieModal';


function Datenschutz() {

  useEffect(() => {

  }, [])

  return (
    <>
 <CookieModal></CookieModal>
      <Card id='card'>
        <Card.Header className="d-flex justify-content-between align-items-center menu-card-header">
          <div className="align-items-center" style={{ marginRight: 8 }}>
            <Image src={`${Logo1}`} style={{ width: 200 }} />
          </div>
          <a href="/" className="btn btn-primary">Home</a>
        </Card.Header>
        <Card.Body className="menu-card-body">
          <div className="background-image">
            <div className="datenschutz-card-content">
              <h2>Datenschutz</h2>
             <p>
              Wir sammeln Kekse für einen guten Zweck.
              </p>     
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center menu-card-footer">
          <p style={{ marginTop: 8, fontSize: 12, color: '#A1A1A1' }}>© 2023 Justus Rademacher</p>
          <p style={{ marginTop: 8, fontSize: 12, color: '#A1A1A1' }}><a href="/">Startseite</a> • <a href="/impressum">Impressum</a></p>
        </Card.Footer>
      </Card>

    </>

  );
}

export default Datenschutz;