/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import CookieModal from '../components/CookieModal';

function ComingSoon(props) {

    useEffect(() => {

    }, [])

    return (
        <>
         <CookieModal></CookieModal>
            <h1>Seite befindet sich in Arbeit</h1>
            <p style={{ marginTop: 8, fontSize: 12, color: '#A1A1A1' }}><a href="/">Zurück</a></p>
        </>
    );
}

export default ComingSoon;