import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const CookieModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Überprüfe, ob der Cookie für die Zustimmung bereits vorhanden ist
    const hasConsent = localStorage.getItem('cookieConsent');
    // Wenn keine Zustimmung vorhanden ist, zeige das Modal
    if (!hasConsent) {
      setShowModal(true);
    }
  }, []);

  const handleConsent = (consent) => {
    // Speichere die Zustimmung im Cookie
    localStorage.setItem('cookieConsent', consent);

    // Verberge das Modal
    setShowModal(false);
  };

  const handleDecline = () => {
    // Wenn der Benutzer ablehnt, leite ihn zur Google-Suchseite weiter
    window.location.href = 'https://www.google.com';
  };

  return (

    <>
    <Modal show={showModal}>
      <Modal.Body>
        <h2>Willkommen auf unserer Webseite</h2>
        <p> Wir möchten darauf hinweisen, dass dies eine private Webseite ist und daher nicht den Datenschutzbestimmungen der DSGVO entspricht. Beim Besuch dieser Seite werden Cookies gespeichert und für verschiedene Zwecke verwendet. Wenn Sie diese Webseite nutzen, stimmen Sie der Verwendung von Cookies zu.</p>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleDecline}>
          Ablehnen
        </Button>
        <Button variant="primary" onClick={() => handleConsent('accepted')}>
          Zustimmen
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default CookieModal;
