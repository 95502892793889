import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Menu from './pages/Menu';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ComingSoon from './pages/ComingSoon';
import Datenschutz from './pages/Datenschutz';
import Impressum from './pages/Impressum';
import Admin from './pages/Admin';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Menu />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/dashboard" element={<Dashboard />} />      
      <Route exact path="/comingsoon" element={<ComingSoon />} />   
      <Route exact path="/datenschutz" element={<Datenschutz />} />
      <Route exact path="/impressum" element={<Impressum />} />
      <Route exact path="/admin" element={<Admin />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root'));