import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const NotificationModal = ({ message, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 2000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <Modal show={true} onHide={onClose}>
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>
      </Modal>    
    );
};

export default NotificationModal;