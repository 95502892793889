// import firebase from "firebase";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    databaseURL: "",
    apiKey: "AIzaSyBj2pbDB800VgYsrRRATw9gCfo8iKzs76U",
    authDomain: "schuhwebsite2.firebaseapp.com",
    projectId: "schuhwebsite2",
    storageBucket: "schuhwebsite2.appspot.com",
    messagingSenderId: "987611593087",
    appId: "1:987611593087:web:cd123aa2ec8592d677824a",
    measurementId: "G-SRKKGS57EQ"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const storage = firebaseApp.storage();
export { db, storage };