import { db } from '../firestore';
import firebase from 'firebase/compat/app';

const FirestoreService = {
    getAllSchuhe(nutzerUID) {
        return db.collection("user").doc(nutzerUID).collection("Schuhe")
            .orderBy("itemCategory", "asc").orderBy("itemName", "asc")
            .get()
            .then(snapshot => snapshot)
            .catch(error => Promise.reject(error));
    },

    getAllSchuhKategorien() {
        return db.collection("SchuhKategorien").orderBy("catName", "asc")
            .get()
            .then(snapshot => snapshot)
            .catch(error => Promise.reject(error));
    },

    getAllSchuhStati() {
        return db.collection("SchuhStati").orderBy("statiNum", "asc")
            .get()
            .then(snapshot => snapshot)
            .catch(error => Promise.reject(error));
    },

    getAllUser() {
        return db.collection("user").orderBy("name", "asc")
            .get()
            .then(snapshot => snapshot)
            .catch(error => Promise.reject(error));
    },

    AddNewSchuh(nutzerUID, itemName, itemCategory, itemPrice, itemSize, itemModellNumber, itemcolor, itemBuyYear, itemText, itemStati) {
        const data = {
            itemName,
            itemCategory,
            itemPrice: itemPrice === "" ? 0 : parseFloat(itemPrice),
            itemSize,
            itemModellNumber,
            itemcolor,
            itemBuyYear: parseInt(itemBuyYear),
            itemText,
            itemStati,
        };

        return db.collection("user").doc(nutzerUID).collection("Schuhe").add(data)
            .then(docRef => docRef)
            .catch(error => Promise.reject(error));
    },

    UpdateSchuh(nutzerUID, SchuhID, itemName, itemCategory, itemPrice, itemSize, itemModellNumber, itemcolor, itemBuyYear, itemText, imageUrl, itemStati) {
        const data = {
            itemName,
            itemCategory,
            itemPrice: itemPrice === "" ? 0 : parseFloat(itemPrice),
            itemSize,
            itemModellNumber,
            itemcolor,
            itemBuyYear: parseInt(itemBuyYear),
            itemText,
            imageUrl,
            itemStati,
        };

        return db.collection("user").doc(nutzerUID).collection("Schuhe").doc(SchuhID).update(data)
            .then(() => Promise.resolve())
            .catch(error => Promise.reject(error));
    },

    DeleteSchuh(nutzerUID, SchuhID) {
        return db.collection("user").doc(nutzerUID).collection("Schuhe").doc(SchuhID).delete()
            .then(() => Promise.resolve())
            .catch(error => Promise.reject(error));
    },

    handleImageUpload(nutzerUID, image, schuhDocId) {
        if (!image) {
            return Promise.reject(new Error('Bild ist leer oder ungültig.'));
        }

        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`images/${nutzerUID}/schuhe/${schuhDocId}`);

        return imageRef.put(image)
            .then(() => imageRef.getDownloadURL())
            .then(url => url)
            .catch(error => Promise.reject(error));
    }
};

export default FirestoreService;
