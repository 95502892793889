import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const ErrorModal = ({ message, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 2000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
      <Modal show={true} onHide={onClose} className="error-modal">
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
    </Modal>
    );
};

export default ErrorModal;