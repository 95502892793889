import React from 'react';
import { Card, CardImg, Row, Col } from 'react-bootstrap';

const MyDataListComponent = ({ schuhe, setCurrentSchuhId, setCurrentSchuh, setAddEditFormType, setShowAddEditForm, setShowDeleteDialogue }) => {
  return (
    <Row xs={1} md={2} lg={3} className="g-4 "> {/* justify-content-center  */}
      {schuhe.map((Schuh, index) => (
        <Col key={index}>
          <Card onClick={() => {
            setCurrentSchuhId(Schuh.doc.key.path.segments[Schuh.doc.key.path.segments.length - 1]);
            setCurrentSchuh({
              "itemName": Schuh.doc.data.value.mapValue.fields.itemName.stringValue,
              "itemCategory": Schuh.doc.data.value.mapValue.fields.itemCategory.stringValue,
              "itemPrice": Schuh.doc.data.value.mapValue.fields.itemPrice.doubleValue ? Schuh.doc.data.value.mapValue.fields.itemPrice.doubleValue : Schuh.doc.data.value.mapValue.fields.itemPrice.integerValue,
              "itemSize": Schuh.doc.data.value.mapValue.fields.itemSize.stringValue,
              "itemModellNumber": Schuh.doc.data.value.mapValue.fields.itemModellNumber.stringValue,
              "itemcolor": Schuh.doc.data.value.mapValue.fields.itemcolor.stringValue,
              "itemBuyYear": Schuh.doc.data.value.mapValue.fields.itemBuyYear.integerValue,
              "itemText": Schuh.doc.data.value.mapValue.fields.itemText.stringValue,
              "imageUrl": Schuh.doc.data.value.mapValue.fields.imageUrl?.stringValue,
              "itemStati": Schuh.doc.data.value.mapValue.fields.itemStati.stringValue
            });
            setAddEditFormType("Edit");
            setShowAddEditForm(true);
          }}>
            <CardImg className="card-img"
              alt={Schuh.doc.data.value.mapValue.fields.itemName.stringValue}
              src={Schuh.doc.data.value.mapValue.fields.imageUrl?.stringValue}
              width="100%"
            />
            <div className="card-overlay">
              <Card.ImgOverlay>
                <Card.Title tag="h5" className="mt-0">{Schuh.doc.data.value.mapValue.fields.itemName.stringValue}</Card.Title>
                <Card.Subtitle style={{ color: '#b0b0b0' }}>{Schuh.doc.data.value.mapValue.fields.itemCategory.stringValue}</Card.Subtitle>
              </Card.ImgOverlay>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default MyDataListComponent;
